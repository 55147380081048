import { useContext, useEffect, useState } from "react";
import { BiLock } from "react-icons/bi";
import * as oauth2 from 'oauth4webapi'
import { Button } from "react-bootstrap";
import OidcContext from "contexts/OidcContext";
import { oidcRedirectUri } from "utils/oidc";

/**
 * 
 * 
 * @returns 
 */
export default function ButtonOidcLogin() {
  const  { as, client } = useContext(OidcContext);
  const  [authorizationUrl, setAuthorizationUrl] = useState<undefined | string>();

  /**
   * 
   * @returns 
   */
  const getAuthorizationUrl = async () => {
    if(!as)
      return;

    const code_verifier = oauth2.generateRandomCodeVerifier()
    const code_challenge = await oauth2.calculatePKCECodeChallenge(code_verifier)
    const code_challenge_method = 'S256'

    localStorage.setItem("code_verifier", code_verifier);
  
    const rawAuthorizationUrl = new URL(as.authorization_endpoint)
    rawAuthorizationUrl.searchParams.set('client_id', client.client_id)
    rawAuthorizationUrl.searchParams.set('code_challenge', code_challenge)
    rawAuthorizationUrl.searchParams.set('code_challenge_method', code_challenge_method)
    rawAuthorizationUrl.searchParams.set('redirect_uri', oidcRedirectUri())
    rawAuthorizationUrl.searchParams.set('response_type', 'code')
    rawAuthorizationUrl.searchParams.set('scope', 'openid email profile')

    setAuthorizationUrl(rawAuthorizationUrl.toString());
  }

  useEffect(() => {
    if(!as)
      return;

    getAuthorizationUrl();
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [as, client]);

  if(!process.env.REACT_APP_OIDC_AUTHORITY || !as)
  return (
    <Button
      variant="danger"
      disabled
      className="mb-0 btn-intercomId"
    >
      <BiLock className="react-icons"/> Intercom ID non disponibile
    </Button>
  );

  return (
    <a
      href={authorizationUrl}
      className="btn btn-danger mb-0 btn-intercomId"
    >
      <BiLock className="react-icons"/> Accedi con Intercom ID
    </a>
  );
}
