import React, { useEffect, useState } from "react";
import { BiSearchAlt } from "react-icons/bi";
import SearchWithResultsModalWindow from "./SearchWithResultsModalWindow";


export default function SearchWithResultsModal() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    const keyListener = (e: KeyboardEvent) => {             
      if (e.ctrlKey && e.key === 'f') {        
        e.preventDefault();

        handleShow();
      }      
    }
    
    window.addEventListener("keydown", keyListener)

    return () => {
      window.removeEventListener("click", keyListener as EventListener, false);
    }
  });


  return (
    <React.Fragment>

      <SearchWithResultsModalWindow show={show} onHide={handleClose} />

      <div className="search-with-results-modal me-3" onClick={handleShow}>

        <div className="search-form">
          <BiSearchAlt className="search-form-icon"/>
        </div>

      </div>
    </React.Fragment>
  );
}
