import { Form, InputGroup } from "react-bootstrap";
import { useField } from "formik";

type ComponentProps = {
  preLabel?: React.ReactNode
  postLabel?: React.ReactNode
  name: string
  style?: React.CSSProperties
  className?: string
  rows?: number
  type?: string
}

/**
 * 
 * @param {*} props 
 * @returns 
 */
export default function InputText(props: ComponentProps) {
  const [field] = useField(props);

  if(props.type === "textarea")
    return <Form.Control as="textarea" {...field} {...props} />;


  if(!(props.preLabel || props.postLabel))
    return <Form.Control {...field} {...props} />;

  const {preLabel, postLabel, ...inputProps} = props;

  return (
    <InputGroup className="mb-2">
      {preLabel && <InputGroup.Text>{preLabel}</InputGroup.Text>}
      <Form.Control {...field} {...inputProps} />
      {postLabel && (
      <InputGroup.Text style={{borderTopLeftRadius:0, borderBottomLeftRadius:0, borderLeft: 0}}>
        {postLabel}
      </InputGroup.Text>
      )}
    </InputGroup>
  );
}
