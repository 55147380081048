import { createContext } from "react";
import { User } from "types/api/User";

type authContext = {
  user?: User,
  setUser: (user?: User) => void
}

/**
 * Holds the information about the currently logged in user
 */
const AuthContext = createContext<authContext>({
  user: undefined,
  setUser: () => {}
});

export default AuthContext;
