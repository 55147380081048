import { createContext } from "react";
import { Supplier } from "types/api/invoicing/supplier";

type invoicesGridContext = {
  period?: string | number,
  associationState?: string,
  supplier?: Supplier,

  setPeriod: (selectedKeys: string | number | undefined) => void,
  setAssociationState: (state: string | undefined) => void,
  setSupplier: (supplier_id: Supplier | undefined) => void,
}

const InvoicesGridContext = createContext<invoicesGridContext>({
  period: undefined,
  associationState: undefined,
  supplier: undefined,
  setPeriod: () => {},
  setAssociationState: () => {},
  setSupplier: () => {}
});

export default InvoicesGridContext;
