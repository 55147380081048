import { Spinner } from "react-bootstrap";

export default function LoadingBlock() {
  
  return (
    <div className="text-center p-5">
      <Spinner animation="border" className="mb-3"/>

      <h4 className="mb-0">Please Wait</h4>
    </div>
  );
}
