import InputWithLabel from 'containers/InputWithLabel';
import { Formik } from 'formik';
import { cloneDeep, identity, pickBy } from 'lodash-es';
import { Button, Form } from 'react-bootstrap';
import { FilterFormComponentProps } from 'types/components/FilterFormComponentProps';
import InputSelect from '../inputs/InputSelect';
import InputSupplierSelect from '../inputs/InputSupplierSelect';
import InvoicesGridContext from 'contexts/InvoicesGridContext';
import { useContext } from 'react';


export default function InvoicesIndexFiltersForm(props: FilterFormComponentProps): JSX.Element {
  const { setAssociationState, setSupplier } = useContext(InvoicesGridContext);
  
  /**
   * 
   */
  const handleFiltersChange = (filters: Record<string, any>) => {
    let validFilters = cloneDeep(filters);

    validFilters = pickBy(filters, identity);

    setSupplier(validFilters.by_supplier);
    setAssociationState(validFilters.by_association_state);

    props.onFilterChange(validFilters);
  }
  
  return (
    <Formik
      onSubmit={handleFiltersChange}
      initialValues={{ by_supplier: "", ...props.activeFilters }}
    >
      {(formik) => (
        <Form onSubmit={formik.handleSubmit}>
   
          <InputWithLabel multiline name="by_supplier" label="Fornitore">
            <InputSupplierSelect name="by_supplier" />
          </InputWithLabel>

          <div className="text-end pt-2">
            <Button variant="primary" size="sm" type="submit">Filtra</Button>
          </div>
        </Form>
      )}
    </Formik>

  );
}
