import Table from 'rc-table';
import { DefaultRecordType } from 'rc-table/lib/interface';
import { ComponentType, useEffect, useState } from 'react';
import { Button, Col, OverlayTrigger, Popover, Row } from 'react-bootstrap';
import { BiData, BiFilterAlt, BiSpreadsheet } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import useSWR from 'swr';
import { FilterFormComponentProps } from 'types/components/FilterFormComponentProps';
import { PrimitiveTableColumn } from 'types/components/ResourceIndexTablePrimitive';
import apiFetcher from 'utils/apiFetcher';
import ResourceTablePagination from './ResourceTablePagination';
import { isObject } from 'lodash-es';
import ErrorBlock from './blocks/ErrorBlock';

type SortParameters = {
  [key: string]: string | number;
}

type ComponentProps = {
  resource: string;
  loading?: boolean;
  columns: PrimitiveTableColumn[];
  rowLinkTo?: string;
  rowOnClick?: (record: DefaultRecordType) => void;
  defaultSort?: SortParameters
  rowKey?: (record: DefaultRecordType) => number | string;
  refreshInterval?: number
  allowXlsxDownload?: boolean
  filtersForm?: ComponentType<FilterFormComponentProps>
  filtersForced?: Record<string, any>
  filtersDefaults?: Record<string, any>
};

/**
 * 
 * 
 * @param props 
 * @returns 
 */
export default function ResourceIndexTablePrimitive(props: ComponentProps): JSX.Element {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState(props.filtersDefaults || {});

  useEffect(() => {
    setPage(1);
  }, [filters]);

  let resourceUrl = `${props.resource}?page=${page}`;

  const sortParams = props.defaultSort || {}
  if(sortParams) {
    Object.keys(sortParams).map(e => resourceUrl += `&sort[${e}]=${encodeURIComponent(sortParams[e])}`)
  }

  if(filters || props.filtersForced) {
    const activeFilters = {...filters, ...props.filtersForced}

    Object.keys(activeFilters).forEach((e: any) => {
      if(!isObject(activeFilters[e]))
        return;
      
      if(!activeFilters[e].id)
        delete activeFilters[e];

        activeFilters[e] = activeFilters[e].id;
    });

    resourceUrl += `&${new URLSearchParams((activeFilters as any)).toString()}`;
  }
  
  const srwOptions = { refreshInterval: 0 };

  if(props.refreshInterval)
    srwOptions["refreshInterval"] = props.refreshInterval * 1000;

  const { data, error } = useSWR(resourceUrl, apiFetcher, srwOptions);

  if (error) return <div style={{width: "100%"}}><ErrorBlock title={error.message}/></div>

  const onRowHandler = (record: DefaultRecordType) => {
    return {
      onClick: () => {
        if(props.rowOnClick)
          props.rowOnClick(record);
      },
      onDoubleClick: () => {
        if(!props.rowLinkTo)
          return;

        navigate(`/${props.rowLinkTo}/${record.id}`);
      }
    }
  };

  /**
   * Updates the filters while resetting the active page 
   * 
   * @param {*} filters An object containing the filters to apply
   */
  const handleFiltersChange = (filters: Record<string, string>) => {
    setPage(1);
    setFilters(filters);
  }
  

  const emptyContent = (
    <div className="text-center p-3">
      <BiData size={60} color="#eee"/>
      <h6 style={{color: "#ddd"}}>Nessun elemento</h6>
    </div>
  );

  const popover = props.filtersForm ? (
    <Popover style={{maxWidth: "400px"}}>
      <Popover.Header ><BiFilterAlt style={{marginTop: "-3px"}} /> Filtri</Popover.Header>
      <Popover.Body className="p-2" style={{minWidth: "350px"}}>
          <props.filtersForm onFilterChange={handleFiltersChange} activeFilters={filters} />
      </Popover.Body>
    </Popover>
  ) : <></>;

  
  return (
    <div className="rc-table-wrapper">
      <div className="wrapper-table">

        <Table
          columns={props.columns}
          data={data?.response || []}
          rowKey={props.rowKey || ((record) => record.id)}
          onRow={onRowHandler}
          className={props.rowLinkTo || props.rowOnClick ? "rc-table-clickable" : ""}
          emptyText={emptyContent}
        />

        {(props.loading || (!data && !error)) && (
          <div className="table-loading">
            <div className="loading-box">
              Attendi...
            </div>
          </div>
        )}

      </div>

      <div className="wrapper-meta">
        <Row>

          {data && (
            <>
            <Col className="meta-text" md={4}>
              {data?.pagination.count} Elementi

              {props.filtersForm && (
                <OverlayTrigger trigger="click" placement="top-start" overlay={popover}>
                  <Button variant="custom" className="ms-3">
                    <BiFilterAlt style={{marginTop: "-3px"}} /> Filtri
                  </Button>
                </OverlayTrigger>
              )}


              {props.allowXlsxDownload && (
                <Button
                  as="a"
                  target="_blank"
                  rel="noopener"
                  variant="custom"
                  className="ms-3"
                  href={"/api/" + resourceUrl.replace(`${props.resource}?page=${page}`, `${props.resource}.xlsx?`)}
                >
                  <BiSpreadsheet style={{marginTop: "-3px"}} /> Esporta .xlsx
                </Button>
              )}

            </Col>

            <Col>
              <ResourceTablePagination
                pagination={data.pagination}
                page={page}
                onPageChange={setPage}
              />
            </Col>
           </>
          )}
        </Row>

      </div>
    </div>
  );
}
