import * as yup from "yup";

const ImportJobFormDefaults = {
  archive: ""
}

const ImportJobFormSchema = yup.object({
  archive: yup.mixed()
    .required()
}).required();
  
export { ImportJobFormDefaults, ImportJobFormSchema }
