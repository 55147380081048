import ErrorBlock from "components/blocks/ErrorBlock";
import LoadingBlock from "components/blocks/LoadingBlock";
import useSWR from "swr";
import { GenericApiResponse } from "types/api/GenericApiResponse";
import { Invoice } from "types/api/invoicing/invoice";
import apiFetcher from "utils/apiFetcher";
import I_Ade_Logo from "assets/images/logos/ade.png";
import I_Tsd_Logo from "assets/images/logos/tsd.svg";
import AttachmentsPopover from "components/AttachmentsPopover";

type ComponentProps = {
  invoiceUuid: false | string
}

export default function InvoiceDetails(props: ComponentProps) {
  const { data, error } = useSWR<GenericApiResponse<Invoice>>(props.invoiceUuid ? `invoicing/invoices/${props.invoiceUuid}` : null, apiFetcher)

  if (error) return <ErrorBlock title={error.message} />
  if(!data) return <LoadingBlock />;

  const apiData = data.response;
  return (
    <>
      <div style={{position: "absolute", top: "-3.1rem", right: "3rem"}}>

        <AttachmentsPopover attachments={apiData.attachments} />

        { apiData.source === "ade" ?
          <img src={I_Ade_Logo} height="26px" className="me-2" alt="Agenzia delle entrate"/>
        :
          <img src={I_Tsd_Logo} height="26px" className="me-2" alt="TS Digital"/>
        }
      </div>

      <div className="invoice-details">
        <iframe title="pdf" src={apiData.document_url} className="details-pdfpreview"/>
      </div>
    </>
  );
}
