import { useField } from "formik";
import Select, { Option } from 'rc-select';
import { useEffect, useState } from "react";
import { BiData } from "react-icons/bi";
import apiFetcher from "utils/apiFetcher";
import { Supplier } from "types/api/invoicing/supplier";

type ComponentProps = {
  name: string
}

/**
 * 
 * @param {*} props 
 * @returns 
 */
export default function InputSupplierSelect(props: ComponentProps) {
  // eslint-disable-next-line
  const [field, meta, helpers] = useField(props);

  const [ items, setItems ] = useState([]);
  const [ isLoading, setIsLoading ] = useState(false);

  const { value } = meta;
  const { setValue } = helpers;

  useEffect(() => {
    if(value)
      handleSearch(value?.name);
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**
   * Updates the value of the input field
   * 
   * @param newValue The new value to set for the input field
   */
  const handleChange = async (newValue: string) => {

    const value = items.find((e: Supplier) => e.id === parseInt(newValue))

    setValue(value);
  }

  /**
   * Asks the backend for the list of Supplier matching the query
   * 
   * @param query The string to send to the backend
   */
  const handleSearch = async (query: string) => {
    setIsLoading(true);
    const res = await apiFetcher(`/invoicing/suppliers/search?q=${query}`);
    
    setItems(res.response);     
    setIsLoading(false);
  }

  const notFoundContent = (
    <div className="text-center p-3">
      <BiData size={60} color="#eee"/>
      <h6 style={{color: "#ddd"}}>Nessun elemento</h6>
    </div>
  );


  return (
    <Select
      value={value?.id}
      filterOption={false}
      placeholder="Seleziona un fornitore"
      optionLabelProp="label"
      notFoundContent={notFoundContent}
      showSearch
      onSearch={handleSearch}
      onChange={handleChange}
      loading={isLoading}
     // className={props.size === "sm" && "size-sm"}
      allowClear={true}
    >

      {items?.length  &&items.map((item: Supplier) => (
        <Option key={item.id} value={item.id} label={item.name}>
          {item.name}
          <div className="text-muted">P.iva: {item.vat_number}</div>
        </Option>
      ))}

    </Select>

  );
}
