import { User } from "types/api/User";
import * as oauth2 from 'oauth4webapi'
import { useContext, useEffect, useState } from "react";
import OidcContext from "contexts/OidcContext";
import { oidcRedirectUri } from "utils/oidc";
import I_Notify from "assets/images/illustrations/notify.svg";
import { BiShapeCircle } from "react-icons/bi";
import { authLogin } from "utils/auth";

type ComponentProps = {
  onLogin: (user: User) => void
  children: React.ReactNode
}

/**
 * 
 */
export default function OidcAuthProcessor(props: ComponentProps) {
  const  { as, client } = useContext(OidcContext);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<undefined | false | string>(false);

  const requestToken = async () => {   
    const currentUrl = new URL(window.location.href)
    const params = oauth2.validateAuthResponse(as, client, currentUrl, oauth2.expectNoState)
    if (oauth2.isOAuth2Error(params)) {
      console.log('error', params)
      setError(params.error_description);
      throw new Error() // Handle OAuth 2.0 redirect error
    }

    const code_verifier = localStorage.getItem("code_verifier");
    if(!code_verifier) {
      setError("No code verifier found");

      return;
    }

    const response = await oauth2.authorizationCodeGrantRequest(as, client, params, oidcRedirectUri(), code_verifier)

    const challenges = oauth2.parseWwwAuthenticateChallenges(response)
    if (challenges) {
      for (const challenge of challenges) {
        console.log('challenge', challenge)
      }
      throw new Error()
    }

    const result = await oauth2.processAuthorizationCodeOpenIDResponse(as, client, response)
    if (oauth2.isOAuth2Error(result)) {
      console.log('error', result)
      setError(result.error_description);
    }
    
    return result.access_token;    
  }

  const handleLogin = async () => {
    const access_token = await requestToken();

    if(!access_token)
      return;

    try {
     const res = await authLogin({ auth: { access_token: access_token } });

     console.log(res);

      props.onLogin(res);
    } catch(e: any) {
      setError(e.message);
    }
  }

  useEffect(() => {

    if (window.location.href.indexOf("authentication/callback") > -1) {
      setIsLoading(true);

      if(!as)
        return;

      handleLogin();
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [as, client]);


  if(error)
    return (
      <div className="login-form" style={{flexDirection: "column"}}>
        <img
          src={I_Notify}
          alt="An error occurred!"
          className="image-fluid mb-5"
          style={{maxWidth: "500px"}}
        />

        <h3 className="text-muted">
          <span className="text-danger">OIDC</span> | {error}
        </h3>

        <a
          href={window.location.origin}
          className="btn btn-secondary mt-5"
        >
          Ricarica la pagina
        </a>
      </div>
    );


  if(isLoading)
    return (
      <div>
        <h1>Invoices</h1>
        <BiShapeCircle className="loading-spinner text-primary" size="50"/>

        <h5 className="mt-5">Autenticazione OIDC in corso...</h5>
      </div>
    );

  return <>{props.children}</>;
}
