import PageHeader from "containers/PageHeader";
import { Col, Row } from "react-bootstrap";
import ImportJobsIndexTable from "components/data/invoicing/importJobs/ImportJobsIndexTable";
import ImportJobUploadPanel from "components/data/invoicing/importJobs/ImportJobUploadPanel";

export default function ImportJobsIndexRoute() {
  

  return (
    <>
      <PageHeader title="Gestione Imports" />

      <div className="container-columns">
        <Row className="g-0" style={{height: "100%"}}>
          <Col className="full-height d-flex" style={{background: "#f5f9fc"}}>
            <ImportJobsIndexTable />
          </Col>
          <Col className="full-height" sm={4}>
            <ImportJobUploadPanel />
          </Col>
        </Row>
      </div>
    </>
  );
}
