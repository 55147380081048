import { Col, ListGroup, ListGroupItem, Row } from "react-bootstrap";
import { Invoice } from "types/api/invoicing/invoice";
import invoiceDocumentTypes from "utils/constants/invoiceDocumentTypes";
import { formatCurrency } from "utils/currencies";
import { prettyDate } from "utils/dates";

type ComponentProps = {
  results: Invoice[],
  onClick?: (uuid: string) => void
};

export default function SearchWithResultsModalResults(props: ComponentProps) {

  const handleResultClick = (uuid: string) => {
    if(props.onClick)
      props.onClick(uuid);
  }

  return (
    <div className="window-results">
      <ListGroup variant="flush">

        {props.results.map(e => (
          <ListGroupItem key={e.uuid} onClick={() => handleResultClick(e.uuid)}>
            <div className={`results-icon ${invoiceDocumentTypes[e.document_type]?.color}`}>
              {e.document_type}
            </div>

            <h6 className="results-title">
              <span className="text-primary">{e.document_number}</span> {e.supplier_name}
            </h6>
            
            <Row className="text-muted">
              <Col>
                {prettyDate(e.document_date)}
              </Col>
              <Col>
                Totale: {formatCurrency(e.price_total)}
              </Col>
              <Col>
                Imposta: {formatCurrency(e.price_taxes)}
              </Col>
            </Row>
          </ListGroupItem>
        ))}

      </ListGroup>
    </div>
  );
}
