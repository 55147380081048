import { createContext } from "react";
import * as oauth2 from 'oauth4webapi'

type oidcContext = {
  as?: any //oauth2.AuthorizationServer
  client: oauth2.Client
}
/**
 * Holds the information about the currently logged in user
 */
const OidcContext = createContext<oidcContext>({
  as: undefined,
  client: {
    client_id: ""
  }
});

export default OidcContext;
