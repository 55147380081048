import { Modal } from "react-bootstrap";
import InvoiceDetails from "./InvoiceDetails";

type ComponentProps = {
  invoiceUuid: false | string
  onClose: () => void
}

export default function InvoiceDetailsModal(props: ComponentProps) {
  
  return (
    <Modal show={!!props.invoiceUuid} size="xl" onHide={props.onClose}> 
      <Modal.Header closeButton>
        <Modal.Title className="me-5">Dettaglio documento</Modal.Title>        
      </Modal.Header>
      <Modal.Body className="p-0">
        <InvoiceDetails invoiceUuid={props.invoiceUuid} />
      </Modal.Body>
    </Modal>
  );
}
