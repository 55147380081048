import { Button, OverlayTrigger, Popover } from "react-bootstrap";
import { BiPaperclip, BiSolidFilePdf } from "react-icons/bi";
import { InvoiceAttachment } from "types/api/invoicing/invoice_attachment";

export default function AttachmentsPopover({ attachments } : { attachments?: InvoiceAttachment[] }) {

  if(!attachments || attachments.length === 0) return <></>;

  const popover = (
    <Popover id="attachments-popover">
      <Popover.Body className="p-2">
        {attachments.map(a => (
          <a href={a.document_url} target="_blank" rel="noreferrer" className="attachments_popover">
            <BiSolidFilePdf className="popover_icon" />
            <div className="popover_content">
              <h5 className="content_name" >{a.name}</h5>
              <p className="content_description" >{a.description}</p>
            </div>
          </a>
        ))}
      </Popover.Body>
    </Popover>
  );

  
  return (
    <OverlayTrigger trigger="click" placement="bottom" overlay={popover}>
      <Button variant="light" className="me-3">
        <BiPaperclip size="20" className="react-icons"/> Allegati
      </Button>
    </OverlayTrigger>
  );
}
