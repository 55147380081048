import Toolbar from 'components/Toolbar';
import Tree, { TreeNodeProps } from 'rc-tree';
import { BiCalendar, BiFolder, BiFolderOpen } from 'react-icons/bi';
import { GenericApiResponse } from 'types/api/GenericApiResponse';
import useSWR from "swr";
import apiFetcher from 'utils/apiFetcher';
import ErrorBlock from 'components/blocks/ErrorBlock';
import LoadingBlock from 'components/blocks/LoadingBlock';
import { InvoicingPeriod } from 'types/api/invoicing/invoicing_period';
import { DataNode, Key } from 'rc-tree/lib/interface';
import { useContext, useState } from 'react';
import InvoicesGridContext from 'contexts/InvoicesGridContext';

const months = ["Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno", "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"]

/**
 * Converts the given periods in a tree structure
 * 
 * @param periods An array or years and months periods
 * @returns A tree structure compatible with rc-tree
 */
function convertToTree(periods: InvoicingPeriod[]): DataNode[] {
  const root: any = {};

  periods.forEach(p => {
    if(!root[p.year]) {
      root[p.year] = {
        key: p.year,
        title: p.year,
        children: []
      }
    }

    root[p.year].children.push({ key: `${p.year}-${p.month}`, title: months[p.month-1] });
  });

  return Object.values(root);
}

export default function PeriodSelectionSidebar({ onSelect } : { onSelect?: (selectedKeys: Key[]) => void }) {
  const { associationState, supplier } = useContext(InvoicesGridContext);
  const [ expandedKeys, setExpandedKeys ] = useState<React.Key[]>([(new Date()).getFullYear()]);

  const apiParams = new URLSearchParams();

  if(associationState)
    apiParams.append("by_association_state", associationState);

  if(supplier)
    apiParams.append("by_supplier", supplier.id.toString());


  const { data, error } = useSWR<GenericApiResponse<InvoicingPeriod[]>>(`invoicing/invoices/periods?${apiParams.toString()}`, apiFetcher)

  if (error) return <ErrorBlock title={error.message} />
  if(!data) return <LoadingBlock />;

  const switcherIcon = (obj: TreeNodeProps) => {
    if(obj.isLeaf) return <BiCalendar />;

    if(obj.expanded) return <BiFolderOpen />;

    return <BiFolder />;
  }

  const handleExpand = (expandedKeys: React.Key[]) => {
    setExpandedKeys(expandedKeys)
  }

  return (
    <div className="columns-column border-end">
      <Toolbar><b>Periodo</b></Toolbar>
      <div className="p-3">
        <Tree
          className="myCls"
          defaultExpandAll
          treeData={convertToTree(data.response)}
          switcherIcon={switcherIcon}
          onSelect={onSelect}
          expandedKeys={expandedKeys}
          onExpand={handleExpand}
        />
      </div>

    </div>
  );
}

