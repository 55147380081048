import React from "react";
import {Pagination } from "react-bootstrap"


type ComponentProps = {
  page: number;
  onPageChange: (page: number) => void;
  pagination: any
};

export default function ResourceTablePagination(props: ComponentProps){
  const page = props.page;
  const onPageChange = props.onPageChange;

  /**
   * 
   * @returns 
   */
  const pages = () => {
    const elements = [];

    for(let i=1; i<=props.pagination.total_pages; i++) {

      if(props.pagination.current_page === i)
        elements.push(<Pagination.Item active key={i}>{i}</Pagination.Item>);
      else {
        if( (i <= 2 || i >= props.pagination.total_pages - 2) || (Math.abs(i - props.pagination.current_page) <= 2) )
          elements.push(<Pagination.Item onClick={() => {props.onPageChange(i)}} key={i}>{i}</Pagination.Item>);

        if(i === props.pagination.current_page - 3 || i === props.pagination.current_page + 3)
          elements.push(<Pagination.Item key={"empty" + i} disabled>...</Pagination.Item>);
      }
    }

    return elements;
  }

  if(!props.pagination)
    return <React.Fragment />;


  return (
    <Pagination>

      <Pagination.First
        disabled={page === 1}
        onClick={() => {onPageChange(1)}}
      />
      <Pagination.Prev
        disabled={page === 1}
        onClick={() => {onPageChange(page - 1)}}
      />

        {pages()}

      <Pagination.Next
        disabled={page === props.pagination.total_pages}
        onClick={() => {onPageChange(page + 1)}}
      />
      <Pagination.Last
        disabled={page === props.pagination.total_pages}
        onClick={() => {onPageChange(props.pagination.total_pages)}}
      />

    </Pagination>

  );
}
