import { EnumList } from "types/EnumList";

const invoiceDocumentTypes: EnumList = {
  TD01: { label: "Fattura", color: "danger" },
  TD04: { label: "Nota di credito", color: "success" },
  TD06: { label: "Parcella", color: "danger" },
  TD17: { label: "Autofattura", color: "warning" },
  TD18: { label: "Integrazione", color: "warning" },
  TD19: { label: "Autofattura", color: "warning" },
  TD24: { label: "Fattura differita", color: "warning" },
  TD25: { label: "Fattura differita", color: "warning" },
};

export default invoiceDocumentTypes;
