import { Col, Row } from 'react-bootstrap';

type ComponentProps = {
  title: React.ReactNode
  className?: string
  style?: React.CSSProperties
  meta?: React.ReactNode
  children?: React.ReactNode
}

export default function PageHeader(props: ComponentProps) {
  return (
    <header className={`p-3 page-header ${props.className}`} style={props.style}>
      <Row>
        <Col>
          <h2 className="mb-0">{props.title}</h2>
          {props.meta}
        </Col>
        
        { props.children && (
          <Col md="auto" className="text-end pt-2">
            {props.children}
          </Col>
        )}

      </Row>
    </header>

  );
}
