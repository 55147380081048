import React, { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { BiSearchAlt } from "react-icons/bi";
import apiFetcher from "utils/apiFetcher";
import SearchWithResultsModalNoResults from "./SearchWithResultsModalNoResults";
import SearchWithResultsModalResults from "./SearchWithResultsModalResults";
import * as Sentry from "@sentry/browser";
import SearchWithResultsModalError from "./SearchWithResultsModalError";
import { Invoice } from "types/api/invoicing/invoice";
import InvoiceViewerContext from "contexts/InvoiceViewerContext";

type ComponentProps = {
  show: boolean,
  onHide: () => void
};

function years() {
  const start_year = 2021;
  const end_year = new Date().getFullYear();

  const years = [];

  for(let y = start_year; y <= end_year; y++) 
    years.push(y);

  return years;
}

export default function SearchWithResultsModalWindow(props: ComponentProps)  {
  const [query, setQuery] = useState("");
  const [filter, setFilter] = useState("");
  const [results, setResults] = useState<Invoice[]>([]);
  const [error, setError] = useState(false);
  const { setUuid } = useContext(InvoiceViewerContext);

  const handleResultClick = (uuid: string) => {
    setUuid(uuid);
  }

  useEffect(() => {
    if(props.show !== false)
      return;

    setQuery("");
    setFilter("");
  }, [props.show]);

  const handleSearch = async (query: string, filter: string) => {
    try {
      setError(false);

      const search_params = new URLSearchParams({ q: query });
      search_params.set("s[document_date_unix]", "desc");

      if(filter)
        search_params.set("f[document_year]", `=|${filter}`);

      const response = await apiFetcher(`/invoicing/invoices/search?${search_params.toString()}`);

      setResults(response.response);
    } catch (err) {
      Sentry.captureException(err);
      setResults([]);
      setError(true);
    }
  }

  const handleQueryChange =  (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
    handleSearch(e.target.value, filter);
  };

  const handleFilterChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    setFilter(e.target.value);
    handleSearch(query, e.target.value);
  };

  return (
      <Modal size="lg" show={props.show} onHide={props.onHide} className="search-with-results-modal-window">
        <Modal.Header closeButton>
          <BiSearchAlt className="window-search-icon" />
          <input
            type="text"
            className="window-search-field"
            placeholder="Cerca..."
            value={query}
            onChange={handleQueryChange}
            autoFocus={true}
          />

          <select
            className="window-search-year-filter"
            onChange={handleFilterChange}
            value={filter}
          >
            <option value="">Tutti</option>
            {years().map(y => <option value={y} key={y}>{y}</option>)}
          </select>
        </Modal.Header>

        {query && (
          <>
            <Modal.Body className="p-0">
              {query && results.length === 0 && !error && <SearchWithResultsModalNoResults query={query} />}
              {query && results.length === 0 && error && <SearchWithResultsModalError />}
              {query && results.length > 0 && <SearchWithResultsModalResults results={results} onClick={handleResultClick}/>}
            </Modal.Body>

            <Modal.Footer className="text-muted">
              Powered by little hamsters 🐹
            </Modal.Footer>
          </>
        )}
      </Modal>
  );
}
