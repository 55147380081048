import ResourceIndexTablePrimitive from 'components/ResourceIndexTablePrimitive';
import { ProgressBar } from 'react-bootstrap';
import { BiX, BiCheck, BiRefresh, BiPause, BiBot } from 'react-icons/bi';
import { PrimitiveTableColumn } from 'types/components/ResourceIndexTablePrimitive';
import { prettyDate } from 'utils/dates';

type ComponentProps = {
  columns?: PrimitiveTableColumn[];
};

const stateIcon = (state: string) => {
  switch(state) {
    case "waiting":
      return <BiPause className="react-icons text-secondary" size={24} />

    case "running":
      return <BiRefresh className="react-icons text-primary icon-spin" size={24}/>

    case "complete":
      return <BiCheck className="react-icons text-success" size={24} />

    case "automatic":
      return <BiBot className="react-icons text-primary" size={20} />
  }

  return <BiX className="react-icons text-danger" size={24} />
}

const computeProgress = (total: number, successes: number, failures: number, ignored: number) => {
  const successPerc = Math.round(successes * 100 / total);
  const failuresPerc = Math.round(failures * 100 / total);
  const ignoredPerc = Math.round(ignored * 100 / total);

  return (
    <ProgressBar>
      <ProgressBar variant="success" now={successPerc} />
      <ProgressBar variant="danger" now={failuresPerc} />
      <ProgressBar variant="secondary" now={ignoredPerc} />
    </ProgressBar>
  )
}

const columns: PrimitiveTableColumn[] = [
  {
    title: "Id",
    dataIndex: 'id',
    className: "text-center",
    width: "5%"
  },
  {
    title: "Stato",
    dataIndex: "state",
    className: "text-center",
    render: v => stateIcon(v)
  },
  {
    title: "Progresso",
    dataIndex: "total",
    width: "30%",
    render: (v,r) => computeProgress(r.documents_count, r.documents_imported, r.documents_failed, r.documents_ignored)
  },
  {
    title: "Totale",
    dataIndex: "documents_count",
  },
  {
    title: "Successi",
    dataIndex: "documents_imported",
  },
  {
    title: "Fallimenti",
    dataIndex: "documents_failed",
  },
  {
    title: "Ignorati",
    dataIndex: "documents_ignored",
  },
  {
    title: "Data",
    dataIndex: "created_at",
    render: v => prettyDate(v, true)
  }
];

/**
 * 
 * 
 * @param props 
 * @returns 
 */
export default function ImportJobsIndexTable(props: ComponentProps): JSX.Element {
    
  return (
    <ResourceIndexTablePrimitive 
      resource="invoicing/import_jobs"
      columns={columns}
      defaultSort={{created_at: "-"}}
      refreshInterval={30}
    />
  );
}
