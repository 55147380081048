import { BiCloudUpload } from "react-icons/bi";
import PageHeader from "containers/PageHeader";
import { Col, Row } from "react-bootstrap";
import PeriodSelectionSidebar from "components/data/invoicing/invoices/PeriodSelectionSidebar";
import InvoicesIndexTable from "components/data/invoicing/invoices/InvoicesIndexTable";
import { Link } from "react-router-dom";
import { useState } from "react";
import { intervalFromYear, intervalFromYearMonth } from "utils/dates";
import InvoicesGridContext from "contexts/InvoicesGridContext";
import { Supplier } from "types/api/invoicing/supplier";

export default function InvoicesIndexRoute() {
  const [period, setPeriod] = useState<string | number | undefined>();
  const [associationState, setAssociationState] = useState<string | undefined>();
  const [supplier, setSupplier] = useState<Supplier | undefined>();

  const handlePeriodSelection = (selectedKeys: (string | number | undefined | bigint)[]) => {
    setPeriod(String(selectedKeys[0]));
  }

  /**
   * Returns an hash of filters to select a specific invoicing interval
   * 
   * @param period The period to convert to an interval
   * @returns The hash of filters with the interval
   */
  const periodToDates = (period?: string | number): Record<string, string> => {
    if(!period) return {};

    const interval = String(period).indexOf("-") >=0 ? intervalFromYearMonth(String(period)) : intervalFromYear(String(period));

    return {"by_period[from]": interval[0], "by_period[to]": interval[1]}
  }

  return (
    <InvoicesGridContext.Provider
      value={{period, setPeriod, associationState, setAssociationState, supplier, setSupplier }}
    >
      <PageHeader title="Fatture">
        <Link className="btn btn-primary" to="/invoicing/import_jobs">
          <BiCloudUpload className="react-icons" size={20}/> Gestione uploads
        </Link>
      </PageHeader>

      <div className="container-columns">
        <Row className="g-0" style={{height: "100%"}}>

          <Col className="full-height" sm={2}>

            <PeriodSelectionSidebar onSelect={handlePeriodSelection} />

          </Col>

          <Col className="full-height d-flex">

            <InvoicesIndexTable filters={{...periodToDates(period)}}/>

          </Col>

        </Row>
      </div>
    </InvoicesGridContext.Provider>
  );
}
