type ComponentProps = {
  children: React.ReactNode
};

export default function Toolbar(props: ComponentProps) {

  return (
    <div className="toolbar">
      {props.children}
    </div>
  );
}