import { Container, Row, Col } from "react-bootstrap"
import { BiBug, BiGitCommit } from "react-icons/bi";

export default function Footer() {
  return (
    <footer className="app-footer pt-2 pb-2">
      <Container fluid>

        <Row>
          <Col>

            <div style={{display: "inline-block", borderRight: "1px solid #c7ccf9", marginRight: "1rem"}}>

              <BiGitCommit size="20" style={{ marginTop: "-2px" }} color="#eb4c27"/>
              <a 
                href={"https://lab.intercom.it/lmanti/invy-inbound-invoices/tree/" + process.env.REACT_APP_VERSION}
                target="_blank"
                rel="noreferrer"
                className="ms-1 me-3"
                style={{ textDecoration: "none" }}
              >
                {process.env.REACT_APP_VERSION}
              </a>

            </div>

            <a
              href="https://lab.intercom.it/lmanti/invoicesbrowser/-/issues/new"
              target="_blank"
              rel="noreferrer"
              style={{textDecoration: "none"}}
            >
              <BiBug className="react-icons" />
              Segnala bug
            </a>

          </Col>
          <Col className="text-end">
            Made with <div className="heartbeat">❤️</div> by &nbsp;
            <a target="_blank" rel="noreferrer nofollow" href="https://intercom.it" style={{textDecoration: "none"}}>
              intercom s.r.l.
            </a>
          </Col>
        </Row>

        </Container>
    </footer>
  );
}
