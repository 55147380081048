import dayjs from "dayjs";
import 'dayjs/locale/it';
const relativeTime = require('dayjs/plugin/relativeTime')

dayjs.extend(relativeTime)
dayjs.locale('it');

/**
 * Returns a string with the given date properly formatted
 * 
 * @param date The date to format
 * @param includeTime Whether or not to include the time in she string
 * @returns The formatted string optionally with time
 */
function prettyDate(date?: Date | string, includeTime = false) {
  if(!date)
    return;

  let format = "DD/MM/YYYY";

  if(includeTime)
    format += " HH:mm";

  return dayjs(date).format(format)
}

/**
 * 
 * @param yearMonth 
 * @returns 
 */
function intervalFromYearMonth(yearMonth: string): string[] {
  const [year, month] = yearMonth.split("-")

  const date = dayjs().year(Number(year)).month(Number(month)-1);

  return [date.startOf('month').format('YYYY-MM-DD'), date.endOf('month').format('YYYY-MM-DD')];
}

/**
 * 
 * @param yearMonth 
 * @returns 
 */
function intervalFromYear(year: string): string[] {

  const date = dayjs().year(Number(year))

  return [date.startOf('year').format('YYYY-MM-DD'), date.endOf('year').format('YYYY-MM-DD')];
}

export { prettyDate, intervalFromYearMonth, intervalFromYear }
