import { Form, InputGroup } from "react-bootstrap";
import { useFormikContext } from "formik";
import { get } from "lodash-es";

type ComponentProps = {
  preLabel?: React.ReactNode
  postLabel?: React.ReactNode
  name: string
  style?: React.CSSProperties
  className?: string
  accept?: string
  disabled?: boolean
}

/**
 * 
 * @param {*} props 
 * @returns 
 */
export default function InputFileUpload(props: ComponentProps) {
  const formik = useFormikContext();

  const handleChange = (event: any) => {
    formik.setFieldValue(props.name, event.currentTarget.files[0]);
  }

  const { preLabel, postLabel, accept, disabled } = props;

  return (
    <div>
      <InputGroup className="mb-2">
        {preLabel && <InputGroup.Text>{preLabel}</InputGroup.Text>}
        <Form.Control onChange={handleChange} type="file" accept={accept} disabled={disabled} />
        {postLabel && (
        <InputGroup.Text style={{borderTopLeftRadius:0, borderBottomLeftRadius:0, borderLeft: 0}}>
          {postLabel}
        </InputGroup.Text>
        )}
      </InputGroup>

      {get(formik.errors, props.name) && get(formik.touched, props.name) ? (
        <div className="text-danger">{get(formik.errors, props.name)}</div>
      ) : null}
    </div>
  );
}
