import ButtonOidcLogin from "components/ButtonOidcLogin";
import { useEffect, useState } from "react";
import { BiShapeCircle } from "react-icons/bi";
import { User } from "types/api/User";
import { authFetch } from "utils/auth";

type ComponentProps = {
  onLogin: (user: User) => void
}

export default function LoginRoute(props: ComponentProps) {
  const [prefetchRunning, setPrefetchRunning] = useState(true);

   /**
   * It's a bit weird to define and run a function inside in other but useEffect doesn't support
   * async callbacks directly due to race conditions.
   */
   useEffect(() => {
    /**
     * Tests if the user is already logged-in by loading its information from the backend,
     * skipping the login screen if the request is successful.
     */
    const prefetch = async () => {
      try {
        const res = await authFetch();

        props.onLogin(res);
      } catch (e) {
        setPrefetchRunning(false);
      }
    }

    prefetch();

  }, [props]);


  if(prefetchRunning)
    return (
      <div>
        <h1>Invoices</h1>
        <BiShapeCircle className="loading-spinner text-primary" size="50"/>
      </div>
    );

  return (
    <div>
      <h1 className="h3 mb-3 font-weight-normal">Accedi</h1>
      <ButtonOidcLogin />
    </div>
  );
}
