import { Badge } from "react-bootstrap";
import { BiHelpCircle } from "react-icons/bi";
import { EnumList } from "types/EnumList";

type ComponentProps = {
  enumValues: EnumList
  value: string
  className?: string
  onlyIcon?: true
  includeIcon?: true
  style?: React.CSSProperties
};

export default function BadgeEnum(props: ComponentProps) {

  let enumValue = props.enumValues[props.value];

  if(!enumValue)
    enumValue = { label: props.value, icon:<BiHelpCircle className="react-icons"/>, color: "secondary" }


  return (
    <Badge
      bg={enumValue.color}
      className={props.className}
      style={props.style}
    >
      {props.onlyIcon ? enumValue.icon : (<>
        {props.includeIcon && enumValue.icon} {enumValue.label}
      </>)}
    </Badge>

  );
}
