import { createContext } from "react";

type invoiceViewerContext = {
  uuid: string | boolean,
  setUuid: (uuid: string | false) => void
}

/**
 * 
 */
const InvoiceViewerContext = createContext<invoiceViewerContext>({
  uuid: false,
  setUuid: (_) => {}
});

export default InvoiceViewerContext;
