import I_Illustration_Taken from "assets/images/illustrations/feeling_blue.svg";
import { Container } from "react-bootstrap";

export default function NotFoundRoute() {
  return (
    <Container fluid className="mt-3 text-center p-5">
        <img
          src={I_Illustration_Taken}
          alt="Nothing here!"
          className="image-fluid mb-5"
          style={{maxWidth: "500px"}}
          />

        <h3 className="text-muted">
          <span className="text-danger">404</span> | Impossibile localizzare la risorsa
        </h3>

    </Container>
  );
}
