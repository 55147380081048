//import BadgeEnum from 'components/BadgeEnum';
import BadgeEnum from 'components/BadgeEnum';
import InvoicesIndexFiltersForm from 'components/forms/filters/InvoicesIndexFilters';
import ResourceIndexTablePrimitive from 'components/ResourceIndexTablePrimitive';
import InvoiceViewerContext from 'contexts/InvoiceViewerContext';
import { useContext } from 'react';
import { BiPaperclip } from 'react-icons/bi';
import { PrimitiveTableColumn } from 'types/components/ResourceIndexTablePrimitive';
import invoiceDocumentTypes from 'utils/constants/invoiceDocumentTypes';
import { formatCurrency } from 'utils/currencies';
import { prettyDate } from 'utils/dates';

type ComponentProps = {
  filters?: Record<string, string>
};

const columns: PrimitiveTableColumn[] = [
  {
    title: "Fornitore",
    dataIndex: 'supplier_name',
  },
  {
    title: "Imponibile",
    dataIndex: 'price_total',
    width: "10%",
    className: "text-end",
    render: (v: number ) => formatCurrency(v)
  },
  {
    title: "Imposta",
    dataIndex: 'price_taxes',
    width: "10%",
    className: "text-end",
    render: (v: number ) => formatCurrency(v)
  },
  {
    title: "Numero",
    dataIndex: 'document_number',
    width: "20%",
    render: (v: string ) => <div className="font-monospace">{v}</div>
  },
  {
    title: <BiPaperclip />,
    dataIndex: 'attachments_count',
    width: "1%",
    render: (v: number ) => v > 0 && <BiPaperclip />
  },
  {
    title: "Tipologia",
    width: "10%",
    dataIndex: 'document_type',
    render: (v: string ) => <BadgeEnum enumValues={invoiceDocumentTypes} value={v} />
  },
  {
    title: "Data",
    width: "20%",
    dataIndex: 'document_date',
    render: v => prettyDate(v)
  }
];

export default function InvoicesIndexTable(props: ComponentProps): JSX.Element {
  const { setUuid } = useContext(InvoiceViewerContext);
    
  return (
    <ResourceIndexTablePrimitive 
      resource="invoicing/invoices"
      columns={columns}
      rowOnClick={(record) => setUuid(record.uuid)}
      defaultSort={{document_date: "-"}}
      rowKey={(record) => record.uuid}
      filtersForced={props.filters}
      filtersForm={InvoicesIndexFiltersForm}
      allowXlsxDownload
    />
  );
}
