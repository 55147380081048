import { BiSearchAlt } from "react-icons/bi";

type ComponentProps = {
  query: string,
};


export default function SearchWithResultsModalNoResults(props: ComponentProps) {
  
  return (
    <div className="window-no-results">
      <BiSearchAlt className="no-results-icon"/>
      <h5 className="no-results-message">Nessun risultato per "<b>{props.query}</b>"</h5>
    </div>
  );
}
