import Toolbar from 'components/Toolbar';
import { Formik } from 'formik';
import { useState } from 'react';
import { Alert, Button, Form, Spinner} from 'react-bootstrap';
import I_Illustration_Going_Up from "assets/images/illustrations/going_up.svg";
import { mutate as swrMutate } from "swr"
import { ImportJobFormDefaults as formDefaults, ImportJobFormSchema as formSchema } from "utils/formsSchemas/invoicing/ImportJob";
import InputFileUpload from 'components/forms/inputs/InputFileUpload';
import useApiMutator from 'hooks/useApiMutator';


export default function ImportJobUploadPanel() {
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const { mutate, isSaving, mutationError } = useApiMutator("invoicing/import_jobs");

  
  const handleSubmit = async (values: any, { resetForm }: any) => {
    setShowSuccessAlert(false);

    const response = await mutate({ import_job: values }, { format:"multipart" });

    if(response.status === "success") {
      setShowSuccessAlert(true);
      swrMutate("invoicing/import_jobs?page=1&sort[created_at]=-");
      resetForm();
    }
  }

  return (
    <div className="columns-column border-start">
      <Toolbar><b>Carica archivio</b></Toolbar>
      <div className="p-5 text-center">

        {showSuccessAlert && <Alert className="mb-5" variant="success">Archivio caricato</Alert>}
        {mutationError && <Alert className="mb-5" variant="danger">Errore durante il caricamento</Alert>}

        <img
          src={I_Illustration_Going_Up}
          alt="Upload"
          className="image-fluid mb-5"
          style={{maxWidth: "250px"}}
        />

        <h3 className="mb-5">Carica archivio fatture</h3>

        <Formik
          onSubmit={handleSubmit}
          validationSchema={formSchema}
          initialValues={formDefaults}
        >
          {(formik) => (   
            <Form noValidate onSubmit={formik.handleSubmit}>

              <div className="mb-5">
                <InputFileUpload name="archive" accept=".zip" disabled={isSaving}/>
              </div>

              <Button
                type="submit"
                variant="primary"
                disabled={isSaving}
              >
                {isSaving && <Spinner animation="border" size="sm" className="me-2" />}
                Carica
              </Button>
            </Form>
          )}
        </Formik>

      </div>
    </div>
  );
}
