import { BiDizzy } from "react-icons/bi";

export default function SearchWithResultsModalError() {
  
  return (
    <div className="window-no-results">
      <BiDizzy className="no-results-icon"/>
      <h5 className="no-results-message">Errore durante la ricerca!</h5>
    </div>
  );
}
