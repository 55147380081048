import OidcContext from 'contexts/OidcContext';
import { useEffect, useState } from 'react';
import RootOfRoutes from 'routes/RootOfRoutes';
import Footer from './Footer';
import Header from './Header';
import * as oauth2 from 'oauth4webapi'
import OidcAuthProcessor from './OidcAuthProcessor';
import LoginRoute from 'routes/Login';
import { User } from "types/api/User";
import AuthContext from 'contexts/AuthContext';
import InvoiceViewer from './InvoiceViewer';

function App() {
  const [user, setUser] = useState<undefined | User>();
  const [as, setAs] = useState<any>();

  const client: oauth2.Client = {
    client_id: process.env.REACT_APP_OIDC_CLIENT_ID || "",
    token_endpoint_auth_method: 'none',
  }

  /**
   * 
   */
  const loadOidcParameters = async () => {
    const issuer = new URL(process.env.REACT_APP_OIDC_AUTHORITY || "")
    const as = await oauth2.discoveryRequest(issuer)
      .then((response) => oauth2.processDiscoveryResponse(issuer, response))

    setAs(as);
  }

  useEffect(() => {
    loadOidcParameters();
  }, [])

  const handleLogin = (user: User) => {
    if (window.location.href.indexOf("authentication/callback") > -1)
      window.location.href = window.origin

    setUser(user);
  }

  if(!user)
    return (
      <div className="App">
        <OidcContext.Provider value={{as, client}}>
          <div className="login-container">
            <OidcAuthProcessor onLogin={handleLogin}>
              <LoginRoute onLogin={handleLogin} />
            </OidcAuthProcessor>
          </div>
        </OidcContext.Provider>
      </div>
    );

  return (
    <AuthContext.Provider value={{user, setUser}}>
      <InvoiceViewer>

        <div className="App">

          <Header />

          <div className="app-container">

            <RootOfRoutes />     

          </div>

          <Footer />

        </div>
        
      </InvoiceViewer>
    </AuthContext.Provider>

  );
}

export default App;
