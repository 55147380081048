import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import I_IntercomLogo from 'assets/images/intercom_logo.svg';
import { Link } from 'react-router-dom';
import React, { useContext } from 'react';
import AuthContext from 'contexts/AuthContext';
import { BiLogOut } from 'react-icons/bi';
import { authLogout } from 'utils/auth';
import SearchWithResultsModal from 'components/modals/SearchWithResults';

export default function Header() {
  const  { user, setUser } = useContext(AuthContext);
  
  /**
   * Handles the logout procedure.
   * Exceptions may be thrown but right now i don't have a nice way of handling them.
   */
    const handleLogout = async () => {
      await authLogout();
  
      setUser(undefined);

      const logoutUrl = new URL(`${process.env.REACT_APP_OIDC_AUTHORITY}/session/end`);
      logoutUrl.searchParams.set('post_logout_redirect_uri', window.location.origin)

      window.location.href = logoutUrl.toString();

    }

    
  const profile = user ? (
    <div className="header-profile">
      <img
        src={`https://libravatar.org/avatar/${user.email_md5}?s=32&d=identicon`}
        alt=""
        className="profile-image"
      />
      {user.firstname} {user.lastname}
    </div>
  ) : <></>;

  return (
    <header className="app-header">
      <Navbar className='app-header' bg="primary" variant="dark" expand="lg">
        <Container fluid>

          <Navbar.Brand>
            <img
              src={I_IntercomLogo}
              height="30"
              className="d-inline-block align-top"
              alt="Intercom Logo"
              style={{marginLeft: "1rem"}}
            />
            <span className="logo-subtitle fw-normal"><b>Inbound</b> Invoices</span>
          </Navbar.Brand>

          {user && (
            <React.Fragment>

              <Navbar.Toggle />

              <Navbar.Collapse id="basic-navbar-nav">

                <Nav className="ms-auto me-3">
                  <Nav.Link as={Link} to="/invoicing/invoices">Fatture</Nav.Link>
                </Nav>

              <SearchWithResultsModal />


              </Navbar.Collapse>

              <NavDropdown title={profile}>
                <NavDropdown.Item className="text-danger" onClick={handleLogout}>
                  <BiLogOut className="react-icons"/> Logout
                </NavDropdown.Item>
              </NavDropdown>
              
            </React.Fragment>
          )}
        </Container>

      </Navbar>

      {process.env.REACT_APP_STAGING && <div className="header-staging"></div>}
    </header>
  );
}
