import { useFormikContext } from "formik";
import { Col, Form, Row } from "react-bootstrap";
import { get, isObject } from "lodash";
import InputText from "components/forms/inputs/InputText";


type ComponentProps = {
  children?: React.ReactNode
  name: string
  label: React.ReactNode
  style?: React.CSSProperties
  multiline?: boolean
  className?: string
  type?: string
  rows?: number
  hint?: string
  preLabel?: React.ReactNode
  postLabel?: React.ReactNode
}

/**
 * 
 * @param {*} props 
 * @returns 
 */
export default function InputWithLabel(props: ComponentProps) {
  const formik = useFormikContext();

  const validationError = () => {
    const errors = get(formik.errors, props.name);

    if(!errors || !get(formik.touched, props.name))
      return;

    return <div className="text-danger">{isObject(errors) ? Object.values(errors).join(', '): errors}</div>
  }

  return (
    <Form.Group as={Row} controlId={props.name} className="mb-3" style={props.style}>

      <Form.Label column sm={props.multiline ? 12 : 3} className={`fw-bold ${props.multiline ? "" : "text-end"}`}>
        {props.label}
      </Form.Label>

      <Col sm={props.multiline ? 12 : 9}>

        {props.type ? <InputText {...props} /> : props.children}

        {props.hint && <span className="text-muted">{props.hint}</span>}

        {validationError()}

      </Col>

    </Form.Group>
  );
}
