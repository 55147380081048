import { BiError } from "react-icons/bi";

type ComponentProps = {
  title?: string,
  hint?: string,
  trace?: string,
};


export default function ErrorBlock(props: ComponentProps) {
  
  return (
    <div className="error-block p-5">
      <BiError size={80} className="mb-3 text-danger" />
      
      <h4>{props.title ? props.title : "Errore generico"}</h4>

      {props.hint && <p className="lead text-muted">{props.hint}</p>}
      
      {props.trace && <div className="trace-container p-3 mt-5">{props.trace}</div>}
    </div>
  );
}
