import { Routes, Route, Navigate } from "react-router-dom";

import NotFoundRoute from "./NotFound";

import InvoicesIndexRoute from "./invoicing/invoices";
import ImportJobsIndexRoute from "./invoicing/importJobs";

export default function RootOfRoutes() {
  return (
    <Routes>
      
      <Route path="/" element={<Navigate to="/invoicing/invoices" replace />} />

      <Route path="/invoicing/invoices" element={<InvoicesIndexRoute />} />
      <Route path="/invoicing/import_jobs" element={<ImportJobsIndexRoute />} />

      <Route path='*' element={<NotFoundRoute />} />
    </Routes>
  );
}
