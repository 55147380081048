import InvoiceDetailsModal from "components/data/invoicing/invoices/InvoiceDetailsModal";
import InvoiceViewerContext from "contexts/InvoiceViewerContext";
import { useEffect, useState } from "react";


type ComponentProps = {
  children: React.ReactNode
}

/**
 * 
 * @param {*} props 
 * @returns 
 */
export default function InvoiceViewer(props: ComponentProps) {
  const [uuid, setUuid] = useState<false|string>(false);

  useEffect(() => {    
    if(window.location.hash)
      setUuid(window.location.hash.replace("#", ""));
  }, []);

  useEffect(() => {
    if(!uuid) {
      window.history.replaceState({}, document.title, window.location.href.split('#')[0]);

      return;
    }

    window.location.hash = uuid;
  }, [uuid]);


  return (
    <InvoiceViewerContext.Provider value={{ uuid, setUuid }}>
      
      {props.children}

      <InvoiceDetailsModal invoiceUuid={uuid} onClose={() => setUuid(false)}/>

    </InvoiceViewerContext.Provider>
  );
}
